/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 57번째 로그에서는 만나통신사: 중국 IT 비즈니스 여행, 구글 클라우드 서밋 참가 후기 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "후원 / 게스트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/1768796"
  }, "팟캐스트 개발자 팟캐스트 stdout.fm 방송듣기, : 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://subicura.com/"
  }, "Subicura's Blog")), "\n"), "\n", React.createElement(_components.h3, null, "새소식: AWS Savings Plans 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/ec2/pricing/reserved-instances/"
  }, "Amazon EC2 예약 인스턴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/savingsplans/pricing/"
  }, "Savings Plans Pricing – Amazon Web Services")), "\n"), "\n", React.createElement(_components.h3, null, "새소식: 타입스크립트 3.7 / 러스트 1.39"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/typescript/announcing-typescript-3-7/"
  }, "Announcing TypeScript 3.7 | TypeScript")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/typescript/announcing-typescript-3-7/#optional-chaining"
  }, "Announcing TypeScript 3.7 #OptionalChaining | TypeScript")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.rust-lang.org/2019/11/07/Rust-1.39.0.html"
  }, "Announcing Rust 1.39.0 | Rust Blog")), "\n"), "\n", React.createElement(_components.h3, null, "새소식: 귀도 반 로썸 은퇴"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.dropbox.com/topics/company/thank-you--guido"
  }, "Thank you, Guido | Dropbox Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/gvanrossum/status/1189546865114529792"
  }, "Guido van Rossum on Twitter: \"It's bittersweet: I'm leaving @dropbox, and am now retired. ... / Twitter")), "\n"), "\n", React.createElement(_components.h3, null, "새소식: 구글 .new 도메인 공개 / 구글 핏빗 인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wired.com/story/google-new-shortcuts-are-now-for-everyone/"
  }, "Google's .New Shortcuts Are Now for Everyone | WIRED")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.new"
  }, "github.new")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/international/article/2019110308591"
  }, "매각가 2조4500억원 핏빗으로 대박 친 40대 한국계 창업자 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mi.com/global/mi-smart-band-4"
  }, "Mi Band 4 - Mi Global Home")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/16046631"
  }, "페이스북, 왓츠앱 23.5조원에 인수 마무리 - 중앙일보")), "\n"), "\n", React.createElement(_components.h3, null, "만나통신사"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mannachina.modoo.at/"
  }, "만나통신사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wechat.com/ko"
  }, "WeChat - Free messaging and calling app")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://post.naver.com/viewer/postView.nhn?volumeNo=18382898&memberNo=32660183"
  }, "소확행 쇼핑의 대명사, ‘노미(NOME)’ : 네이버 포스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/economy/article/201911069275Y"
  }, "알리페이·위챗페이 中 방문 외국인에 앱 결제 개방 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.didiglobal.com/"
  }, "Homepage - DiDi official website")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.meituan.com/home"
  }, "메이투완 - 美团企业官网-美团点评官方网站")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.baidu.com/"
  }, "baidu - 百度一下，你就知道")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kg.qq.com/"
  }, "전민K가 - 全民K歌 - 你其实很会唱歌!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.heytea.com/"
  }, "희차 - 喜茶HEYTEA--唯一官网")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gotokeep.com/"
  }, "Keep - 自律给我自由: 健身跑步瑜伽,达人力荐的运动社区")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kotra.or.kr/user/globalAllBbs/kotranews/album/2/globalBbsDataAllView.do?dataIdx=175765"
  }, "스포츠 앱 'Keep'으로 살펴보는 중국 플랫폼 비즈니스 성공 전략 - 뉴스 - KOTRA 해외시장뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://equalocean.com/internet/20190114-keeps-next-step-is-to-bring-online-and-offline-together"
  }, "Keep’s Next Step Is to Bring Online and Offline Together - EqualOcean")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.kotra.or.kr/kotranews/index.do"
  }, "KOTRA 해외시장뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://platum.kr/archives/98267"
  }, "메이투안 디엔핑, 모바이크 인수합병... 인수가 2.9조 원 - 'Startup's Story Platform’")), "\n"), "\n", React.createElement(_components.h3, null, "구글 클라우드 서밋 서울 2019"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://inthecloud.withgoogle.com/summit-sel-19/home.html?summit=globalpage"
  }, "Google Cloud Summit")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/subicura/status/1191923255302447104"
  }, "subicura on Twitter: \"구글 클라우드 서밋 2019 식사 ㅎㅎ 자리로 가져다 주심 좋다 https://t.co/F5Ll6Jrg2v\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/subicura/status/1191973240039759872"
  }, "subicura on Twitter: \"클라우드 아케이드 게임! ㅎㅎ #GoogleCloudSummit https://t.co/NtmHqEC1GN\" / Twitter")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
